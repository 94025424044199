@import 'reset.css';

body,textarea,input,select {font-family: 'Montserrat', sans-serif; margin: 0; padding: 0; border: 0; outline: 0;}

body {line-height: 1.3em; color: #3f3e3a; font-size: 62.5%; background: #ffffff url(/images/bg.jpg) no-repeat center top fixed;}

body {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

a {color: #e04193; text-decoration: underline;}
a:hover {color: #e04193; text-decoration: underline;}

.clr {clear: both; font-size: 1px; line-height: 1px; height: 0; overflow: hidden;}

.clear:after {
    content:".";
    display:block;
    height:0;
    clear:both;
    visibility:hidden;
}
.clear {display:inline-block;}
/* Hide from IE Mac \*/
.clear {display:block;}
/* End hide from IE Mac */

::selection{background:#43bf76;color:#ffffff;}::-moz-selection{background:#43bf76;color:#ffffff;}

.image_left {
    float: left;
    margin: 0 40px 20px 0;
}

.image_right {
    float: right;
    margin-left: 0 0 20px 40px;
}

.button, a.button {
    font-size: 18px;
    text-transform: uppercase;
    line-height: 22px;
    color: #ffffff;
    padding: 15px 25px;
    font-weight: bold;
    display: inline-block;
    *zoom: 1;
    *display: inline;
    margin: 0;
    border: none;
    background: #00c072;
    cursor: pointer;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-decoration: none!important;
}

/* grid */
/*  SECTIONS  */
.section {
    clear: both;
    padding: 0px;
    margin: 0px;
}

/*  COLUMN SETUP  */
.col {
    display: block;
    float:left;
    margin: 0 0 1% 4%;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }

/*  GRID OF TWO  */
.span_2_of_2 {
    width: 100%;
}
.span_1_of_2 {
    width: 48%;
}

/*  GRID OF THREE  */
.span_3_of_3 { width: 100%; }
.span_2_of_3 { width: 65.33%; }
.span_1_of_3 { width: 30.66%; }

/*  GRID OF FOUR  */
.span_4_of_4 {
    width: 100%;
}
.span_3_of_4 {
    width: 74%;
}
.span_2_of_4 {
    width: 48%;
}
.span_1_of_4 {
    width: 22%;
}

/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 680px) {
    .col {  margin: 0 0 1% 0%; }
    .span_1_of_4, .span_2_of_4, .span_3_of_4, .span_4_of_4 { width: 100%; }
    .span_3_of_3, .span_2_of_3, .span_1_of_3 { width: 100%; }
    .span_2_of_2, .span_1_of_2 { width: 100%; }
}

/* page */
.b-layout, .b-block-wrap {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.h-block-wrap {

}

.b-holder {
    overflow: hidden;
    zoom: 1;
}

.b-content {

}

.b-content.sidebar {
    width: 100%;
    margin-right: -350px;
    float: left;
}

.b-content.sidebar .b-content-block {
    margin-right: 350px;
}

.b-sidebar-block {
    float: right;
    width: 320px;
    margin-bottom: 30px;
}

.b-block-name, h2.name {
    font-size: 3.2em;
    color: #ffffff;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: inline-block;
}

.b-block-name.green {
    color: #00c072;
}

h1.name {
    font-size: 3.2em;
    color: #ffffff;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 20px;
    text-transform: uppercase;
    display: inline-block; 
}

.b-content-holder {
    background: #fff;  
    position: relative;
    -webkit-border-top-left-radius: 46px;
    -webkit-border-top-right-radius: 46px;
    -moz-border-radius-topleft: 46px;
    -moz-border-radius-topright: 46px;
    border-top-left-radius: 46px;
    border-top-right-radius: 46px;
}

.h-content-holder {
    padding: 30px 60px; 
    position: relative;  
}

.b-content-top {
    background: url(/images/about_header.jpg) no-repeat bottom left;
    padding: 80px 60px 35px 160px;  
    -webkit-border-top-left-radius: 46px;
    -webkit-border-top-right-radius: 46px;
    -moz-border-radius-topleft: 46px;
    -moz-border-radius-topright: 46px;
    border-top-left-radius: 46px;
    border-top-right-radius: 46px;
}

body.feedback .b-content-top {
    background: url(/images/contacts_header.jpg) no-repeat bottom left;   
}

body.video_index .b-content-top, body.video_show .b-content-top, body.video .b-content-top {
    background: url(/images/video_header.jpg) no-repeat bottom left;   
}

body.argentum_page_shop .b-content-top, body.merch .b-content-top {
    background: url(/images/shop_header.jpg) no-repeat bottom left;   
}

body.event .b-content-top {
    background: url(/images/masterclasses_header.jpg) no-repeat bottom left;   
}

body.team .b-content-top {
    background: url(/images/blogers_header.jpg) no-repeat bottom left;   
}

body.argentum_tv_program_list .b-content-top, body.argentum_tv_program_show .b-content-top {
    background: url(/images/behappy_header.jpg) no-repeat bottom left;   
}

.b-all {
    text-align: center;
}

.b-share {
    margin-bottom: 30px;
}

h1.title {
    font-size: 3.6em;
    font-weight: bold;
    color: #1c1c1b;
    line-height: 1.1em;
    margin-bottom: 20px;
}

.b-event-show h1.title, .b-merch h1.title {
    padding-right: 180px;
}

/* header */
.b-header {
    padding: 0 20px;
    position: relative;
    max-width: 1200px;
    margin: 0 auto 38px auto;
}

.b-logo {
    position: absolute;
    top: -415px;
    left: 50%;
    z-index: 1;
    margin-left: -83px;
    -webkit-transition: all 1s ease-in;
    -moz-transition: all 1s ease-in;
    -ms-transition: all 1s ease-in;
    -o-transition: all 1s ease-in;
    transition: all 1s ease-in;
}

body.ready .b-logo {
    top: 10px;
}

.b-logo a {
    display: inline-block;
}

.b-logo img {
    max-width: 100%;
}

.b-header-holder {
    position: relative;
}

.b-lang {
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    top: 90px;
    right: 60px;
    z-index: 1;  
    padding: 5px 10px;
    background: url(/images/menu_bg.png);
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -webkit-box-shadow: -1px 4px 1px 1px rgba(0,0,0,0.35);
    -moz-box-shadow: -1px 4px 1px 1px rgba(0,0,0,0.35);
    box-shadow: -1px 4px 1px 1px rgba(0,0,0,0.35);
}

.b-lang a {
    color: #43bf76;
}

.b-lang a.active, .b-lang a:hover {
    color: #43bf76;
    text-decoration: none;
}

/* menu */
.b-main-menu {
    padding-top: 166px;     
    position: relative;
}

body.welcome .b-main-menu {
    margin-bottom: 50px;
}

.h-main-menu {
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
    padding: 30px 60px 15px 60px;
    background: url(/images/menu_bg.png);
    -webkit-border-radius: 46px;
    -moz-border-radius: 46px;
    border-radius: 46px;
    -webkit-box-shadow: -1px 16px 1px 1px rgba(0,0,0,0.35);
    -moz-box-shadow: -1px 16px 1px 1px rgba(0,0,0,0.35);
    box-shadow: -1px 16px 1px 1px rgba(0,0,0,0.35);
}

.b-site-nav {
    z-index: 99;
    position: relative;
}

.b-menu-ico {
    display: none;
}

.b-menu-ico a {
    width: 24px;
    height: 24px;
    background: url(/images/menu_ico.png) no-repeat 0 0;
    display: block;
}

.b-site-nav li {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    padding-right: 18px;
    background: url(/images/menu_sep.png) no-repeat 100% 8px;
}

.b-site-nav a {
    color: #43bf76;
    font-size: 1.6em;
    line-height: 1.3em;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 2px solid transparent;
}

.b-site-nav li.last {
    padding-right: 0;
    background: none;
}

.b-site-nav a:hover, .b-site-nav a.active {
    color: #43bf76;
    text-decoration: none;
    border-bottom: 2px solid #43bf76;
}

.b-site-nav ul ul {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #00c072;
    z-index: 332;
}

.b-site-nav li li {
    margin-right: 0;
    font-size: 14px;
}

.b-site-nav li li a {
    color: #ffffff;
    padding: 5px 10px;
    background: none;
    display: block;
}

.b-site-nav li li a:hover {
    color: #ffffff;
    text-decoration: underline;
}

.b-social {

}

.b-social a {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin: 0 5px;
}

.b-social a.youtube {
    background: url(/images/social.png) no-repeat -61px 0;
}

.b-social a.instagram {
    background: url(/images/social.png) no-repeat -119px 0;
}

.b-social a.facebook {
    background: url(/images/social.png) no-repeat 0 0;
}

/* slider */
.b-slider-holder {
    position: relative;
    margin: 0 auto 40px auto;
    padding-bottom: 60px;
}

/* main page */
.b-block-about {
    background: #ffffff;
    position: relative;
}

.b-block-about-top {
    height: 31px;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: -30px;
    background: url(/images/about_top.png) repeat-x center top;
}

.b-block-about-text {
    background: url(/images/anna.png) no-repeat bottom left;
    padding: 100px 0 150px 490px;
}

.b-block-social {
    text-align: center;
    position: relative;
    background: #e04193 repeat-x center top;  
}

.h-block-social {
    padding: 70px 0;
}

.b-social-top {
    background: url(/images/social_top.png) repeat-x center top;
    height: 40px;
    position: absolute;
    top: -38px;
    width: 100%;
}

.b-block-social-ico {
    padding-top: 40px;
    text-align: center;
}

.b-block-social-ico a {
    display: inline-block;
    margin: 0 20px;
}

.b-main-links {
    margin-bottom: 40px;
    position: relative;
}

.b-main-anons {
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

.b-main-anons-img img {
    max-width: 100%;
}

.b-main-anons-name {
    position: absolute;
    bottom: 80px;
    width: 100%;
}

.b-main-anons-name h2 {
    font-size: 2em;
    color: #000000;
    line-height: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
}

.b-main-anons-name h2 a {
    color: #000000;
    text-decoration: none;  
}

/* static text */
.b-static-text {

}

.b-static-text.fullwidth {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
}

.b-static-text.error {
    padding: 50px 0;
}

.b-static-img {
    position: relative;
    margin-bottom: 20px;
}

.b-static-img .jwplayer {
    max-width: 100%;   
}

.b-static-text img, .b-static-img img, .b-static-img iframe, .b-static-text video, .b-static-text object, .b-static-text embed, .b-static-text iframe, .b-static-video embed, .b-static-video video, .b-static-video object, .b-static-video iframe {
    max-width: 100%;
}

.b-static-text img, .b-static-img img {
    height: auto;
}

.b-static-img.b-news-image img {
    width: 100%;
}

.b-static-text h1 {
    font-size: 3.2em;
    color: #3cad6b;
    line-height: 1.2em;
    margin-bottom: 20px;
}

.b-static-text h2 {
    font-size: 2.6em;
    color: #3cad6b;
    line-height: 1.2em;
    margin-bottom: 15px;
    font-weight: bold;
}

.b-static-text h3 {
    font-size: 2.6em;
    color: #35363e;
    line-height: 1.2em;
    margin-bottom: 20px;
    font-weight: bold;
}

.b-static-text h4 {
    font-size: 2em;
    color: #35363e;
    line-height: 1.3em;
    margin-bottom: 20px;
    font-weight: bold;
}

.b-static-text p {
    font-size: 1.8em;
    color: #000000;
    line-height: 1.4em;
    padding-bottom: 20px;
    font-weight: normal;
}

.b-static-text p strong {
    font-weight: bold;
}

.b-static-text p em {
    font-style: italic;
}

.b-static-text blockquote {
    font-size: 3.2em;
    line-height: 1em;
    padding-left: 60px;
    font-weight: bold;
    color: #e04193;
    font-style: italic;
    margin-bottom: 30px;
    clear: both;
}

.b-static-text blockquote p {
    font-size: 32px;
    color: #e04193;
    font-weight: bold;
    padding: 0;
}

.b-static-text ul, .b-static-text ol {
    margin: 0 0 20px 32px;
}

.b-static-text ul li {
    font-size: 1.8em;
    color: #000000;
    line-height: 1.4em;
    padding-bottom: 8px;
    list-style: disc;
    list-style-position: inside;
    font-weight: normal;
}

.b-static-text ol li {
    font-size: 1.8em;
    color: #000000;
    line-height: 1.4em;
    padding-bottom: 8px;
    list-style: decimal;
    font-weight: normal;
}

.b-static-text table {
    width: 100%;
    margin-bottom: 20px;
}

.b-static-text th, .b-static-text thead td {
    font-size: 1.8em;
    font-weight: bold;
    vertical-align: top;
    line-height: 1.3em;
    color: #1f3353;
    text-align: left;
    padding: 10px;
    border: 2px solid #1f3353;
}

.b-static-text td {
    font-size: 1.5em;
    vertical-align: top;
    line-height: 1.4em;
    color: #1f3353;
    font-weight: 300;
    text-align: left;
    padding: 10px;
    border: 1px solid #e6e6e8;
}

.b-static-text td p {
    font-size: 15px;
    font-weight: 300;
    color: #1f3353;
    padding: 0;
}

/* gallery */
.b-media-gallery {
    position: relative;
    overflow: hidden;
    zoom: 1;
    margin-bottom: 30px;
}

.flexslider {
    border: none!important;
    background: none!important;
    margin-bottom: 0!important;
    -webkit-border-radius: 0!important;
    -moz-border-radius: 0!important;
    border-radius: 0!important;
}

.flex-direction-nav a {
    text-decoration: none;
    display: block;
    width: 26px!important;
    height: 60px!important;
    margin: -30px 0 0 0!important;
    position: absolute;
    top: 45%;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.flex-direction-nav .flex-prev {
    left: 20px;
    background: url(/images/nav.png) no-repeat 0 0;
    text-indent: -9999px!important;
    font-size: 0!important;
}

.flex-direction-nav .flex-next {
    right: 20px;
    text-align: right;
    background: url(/images/nav.png) no-repeat 100% 0;
    text-indent: -9999px!important;
    font-size: 0!important;
}

.flex-direction-nav a:before, .flex-direction-nav a:before {
    font-size: 0!important;    
}

/* news */
.b-news-list {

}

.b-news-item {
    margin-bottom: 30px;
}

.b-news-img {
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.b-news-img img {
    display: block;
    max-width: 100%;
}

.b-news-info {
    padding-top: 20px;
}

.b-news-title {
    padding-bottom: 10px;
}

.b-news-title h2 {
    font-weight: bold;
    font-size: 1.8em;
    color: #000000;
    line-height: 1.3em;
}

.b-news-title h2 a {
    text-decoration: none;
    color: #000000;
}

.b-news-desc {
    font-size: 1.4em;
    color: #1b1814;
    line-height: 1.3em;
}

.b-news-desc a {
    color: #1b1814;
    text-decoration: none;
}

.b-news-date {
    font-size: 1.4em;
    color: #adadad;
    line-height: 1.3em;
    margin-bottom: 10px;
}

/* video */
.b-video-list {

}

.b-video-categories {
    margin-bottom: 20px;   
    font-size: 1.3em; 
}

.b-video-categories a {
    display: inline-block;
    padding: 8px;
    background: #77a3ee;
    color: #fff;
    text-decoration: none;
    margin: 0 8px 5px 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-video-item {
    margin-bottom: 30px;
}

.b-video-img {
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    position: relative;
}

.b-video-img .play {
    display: block;
    position: absolute;
    top: 60px;
    left: 50%;
    margin-left: -30px;
    width: 61px;
    height: 69px;
    background: url(/images/play_ico.png) no-repeat top left;
}

.b-video-img img {
    display: block;
    max-width: 100%;
}

.b-video-info {
    padding-top: 20px;
}

.b-video-title {
    padding-bottom: 10px;
}

.b-video-title h2 {
    font-weight: bold;
    font-size: 1.8em;
    color: #000000;
    line-height: 1.3em;
}

.b-video-title h2 a {
    text-decoration: none;
    color: #000000;
}

.b-video-desc {
    font-size: 1.4em;
    color: #1b1814;
    line-height: 1.3em;
}

.b-video-desc a {
    color: #1b1814;
    text-decoration: none;
}

.b-video-date {
    font-size: 1.4em;
    color: #adadad;
    line-height: 1.3em;
    margin-bottom: 10px;
}

.b-video-category {
    font-size: 1.4em;
    color: #1b1814;
    line-height: 1.3em;   
}

.b-video-category.inside {
    margin-bottom: 20px;
}

.b-video-other {
    margin-bottom: 30px;
}

/* merch */
.b-merch-list {

} 

.b-merch-item {
    margin-bottom: 30px;   
}

.b-merch-image {
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.b-merch-image img {
    display: block;
    max-width: 100%;   
}

.b-merch-name {
    padding-bottom: 10px;
}

.b-merch-name h3 {
    font-weight: bold;
    font-size: 1.8em;
    color: #000000;
    line-height: 1.3em;
}

.b-merch-name h3 a {
    text-decoration: none;
    color: #1c1c1b;
}

.b-merch-desc {
    font-size: 1.4em;
    color: #1b1814;
    line-height: 1.3em;
    margin-bottom: 15px;
}

.b-merch-desc a {
    color: #1b1814;
    text-decoration: none;
}

.b-merch-price {
    font-size: 1.6em;
    color: #43bf76;
    line-height: 1.3em;
    font-weight: bold;
}

.b-merch-goto {
    position: absolute;
    top: 30px;
    right: 60px;
    z-index: 1;
}

.b-merch-goto a {
    display: inline-block;
    font-size: 2.4em;
    line-height: 1.2em;
    padding: 10px 20px;
    color: #00c072;
    text-decoration: none;
    border: 2px solid #00c072;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
}

/* event */
.b-event-list {

}

.b-event-item {
    margin-bottom: 30px;
}

.b-event-img {
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}

.b-event-img img {
    display: block;
    max-width: 100%;
}

.b-event-info {
    padding-top: 20px;
}

.b-event-title {
    padding-bottom: 10px;
}

.b-event-title h2 {
    font-weight: bold;
    font-size: 1.8em;
    color: #000000;
    line-height: 1.3em;
}

.b-event-title h2 a {
    text-decoration: none;
    color: #1c1c1b;
}

.b-event-desc {
    font-size: 1.4em;
    color: #1b1814;
    line-height: 1.3em;
}

.b-event-desc a {
    color: #1b1814;
    text-decoration: none;
}

.b-event-place {
    font-size: 1.6em;
    color: #43bf76;
    line-height: 1.3em;
    font-weight: bold;
    padding-left: 26px;
    margin-bottom: 15px;
    background: url(/images/loc_ico.png) no-repeat 3px 0;    
}

.b-event-date {
    font-size: 1.6em;
    color: #adadad;
    line-height: 1.3em;
    margin-bottom: 15px;
    font-weight: bold;
    padding-left: 26px;
    background: url(/images/cal_ico.png) no-repeat top left;
}

.b-event-join {
    position: absolute;
    top: 30px;
    right: 60px;
    z-index: 1;
}

.b-event-join a {
    display: inline-block;
    font-size: 2.4em;
    line-height: 1.2em;
    padding: 10px 20px;
    color: #c83f8d;
    text-decoration: none;
    border: 2px solid #c83f8d;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;
}

/* team */
.b-team-item {
    margin-bottom: 30px;
    text-align: center;
} 

.b-team-image {
    margin-bottom: 20px;
}

.b-team-image img {
    max-width: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.b-team-about h3 {
    color: #1c1c1b;
    font-size: 3.2em;
    font-weight: normal;
    line-height: 1.2em;
    margin-bottom: 10px;
}

.b-team-desc {
    font-size: 1.4em;
    color: #1b1814;
    line-height: 1.3em;
}

/* contacts */
.b-mapa {

}

.b-mapa.margin {
    margin-bottom: 50px;
}

#mapa {
    width: 100%;
    height: 360px;
    margin: 0 0 30px 0;
    padding: 0px;
}

/* tv */
.b-tv-list {

} 

.b-tv-item {
    margin-bottom: 30px;
}

.b-tv-img {
    -moz-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

.b-tv-img img {
    max-width: 100%;
    display: block;
}

.b-tv-info h2 {
    color: #1c1c1b;
    font-size: 3.2em;
    font-weight: normal;
    line-height: 1.2em;
}

.b-tv-info h2 a {
    color: #1c1c1b;
    text-decoration: none;   
}

.b-tv-desc {
    font-size: 1.4em;
    color: #1b1814;
    line-height: 1.3em;
    padding-top: 20px;
    font-weight: normal;
}

.b-tv-desc.full {
    padding: 0 0 20px 0;
}

.b-tv-desc a {
    color: #1b1814;  
    text-decoration: none; 
}

.b-tv-timeinfo {
    font-size: 2.4em;
    color: #42ad3f;
    font-weight: bold;
    line-height: 1.4em;   
    padding-top: 10px;
}

.b-tv-timeinfo.full {
    margin-bottom: 20px;
}

.b-tv-param {
    margin-bottom: 20px;
}

.b-tv-param dt {
    font-size: 2em;
    color: #b7b7b7;
    font-weight: 600;
    line-height: 1.3em;
    display: block;
    text-transform: uppercase;
}

.b-tv-param dd {
    font-size: 1.8em;
    color: #000000;
    line-height: 1.4em;
    padding-bottom: 20px;
}

/* search */
.b-search-form {
    margin-bottom: 30px;
    position: relative;
}

.b-search-form .input {
    background: #ffffff;
    border: 1px solid #e6e6e8;
    color: #3d3c3b;
    padding: 8px 40px 8px 8px;
    font-size: 13px;
    line-height: 16px;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-search-submit {
    position: absolute;
    z-index: 3;
    top: 5px;
    right: 5px;
}

.b-search-form .srcbutton {
    text-indent: -9999px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: block;
    background: url(/images/search_ico.png) no-repeat 0 0;
}

.b-search-count {
    font-size: 1.4em;
    color: #1c1c1b;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #e8e8eb url(/images/form_ico.png) no-repeat 20px -88px;
    margin-bottom: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-search-error, .b-search-hint {
    font-size: 1.4em;
    color: #c67171;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #f1d7d7 url(/images/form_ico.png) no-repeat 20px 15px;
    margin-bottom: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

/* pager */
.b-page-selector {
    text-align: center;
    position: relative;
    zoom: 1;
    overflow: hidden;
    margin-bottom: 30px;
}

.b-page-selector a, .b-page-selector span.current {
    text-decoration: none;
    color: #ffffff;
    font-size: 2em;
    line-height: 1.2em;
    color: #1b1814;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    padding: 3px 10px;
    border: 2px solid #f3f3f5;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-page-selector span {
    display: inline-block;
    margin: 0 2px;
}

.b-page-selector span.current {
    text-decoration: none;
    color: #43bf76;
    border: 2px solid #43bf76; 
    margin: 0 2px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-page-selector a:hover {
    text-decoration: none;
    color: #43bf76;
    border: 2px solid #43bf76; 
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-page-selector .last, .b-page-selector .first {
    display: none;
}

.b-page-selector .next, .b-page-selector .previous {
    text-indent: -9999px;
    vertical-align: -2px;
}

.b-page-selector .next a {
    width: 20px;
    height: 20px;
    padding: 5px;
    background: url(/images/page_nav.png) no-repeat -30px 8px;
    display: block;
}

.b-page-selector .previous a {
    width: 20px;
    height: 20px;
    padding: 5px;
    background: url(/images/page_nav.png) no-repeat 9px 8px;
    display: block;
}

/* form */
.b-form {
    margin-bottom: 30px;
}

.b-contacts-form p {
    font-size: 3.2em!important;
    color: #42ad3f!important;
    font-weight: 300!important;
    margin-bottom: 15px;
    padding-bottom: 0!important;
}

.b-contacts-form .label {
    display: none;
}

.b-form p {
    font-size: 1.4em;
    color: #010f11;
    line-height: 1.3em;
    padding-bottom: 15px;
}

.b-form-row {
    margin-bottom: 20px;
}

.b-form-row p.hint {
    display: none;
}

.b-form-row label {
    font-size: 1.6em;
    color: #010f11;
    line-height: 1.3em;
    padding: 0 14px 10px 0;
    display: table-cell;
}

.b-form-row label[for=remember_me] {
    display: inline-block;
}

.b-form-row label.required {
    background: url(/images/asterisk.gif) no-repeat top right;
}

.b-form .input, .b-form select, .b-form textarea {
    font-size: 14px;
    color: #9d9d9d;
    font-weight: normal;
    width: 100%;
    margin: 0;
    background: #ffffff;
    border: 1px solid #e1e1e2;
    padding: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

#fos_user_profile_form_dateOfBirth select {
    width: 70px;
    margin-right: 10px;
}

.b-form textarea {
    width: 100%;
    height: 80px;
}

.b-form-row .form-help-label {
    font-size: 1.3em;
    color: #9d9d9d;
    padding-top: 5px;
}

.b-form-row .errors li {
    font-size: 1.3em;
    color: #e39494;
    padding-bottom: 5px;
}

.b-form-submit {

}

.b-form-done, .flash-success {
    font-size: 1.4em;
    color: #83c671;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #e2f1d7 url(/images/form_ico.png) no-repeat 20px -36px;
    margin-bottom: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-form-notice {
    font-size: 1.4em;
    color: #1c1c1b;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #e8e8eb url(/images/form_ico.png) no-repeat 20px -88px;
    margin-bottom: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-form-error {
    font-size: 1.4em;
    color: #c67171;
    line-height: 1.3em;
    padding: 16px 15px 15px 55px;
    background: #f1d7d7 url(/images/form_ico.png) no-repeat 20px 15px;
    margin-bottom: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.form-help-label {
    display: none;
}

/* back-top */
#back-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 333;
  width: 100px;
  height: 105px;
}

#back-top a {
  display: block;
  width: 100px;
  height: 105px;
  background: url(/images/up_btn.png) no-repeat 0 0;
}

#back-top a:hover {

}

/* footer */
.b-footer {
    background: #43bf76;
    text-align: center;
    position: relative;
}

.b-footer-top {
    background: url(/images/footer_top.png) repeat-x center top;  
    height: 40px;
    position: absolute;
    top: -38px;
    width: 100%;
}

.h-footer {
    max-width: 1110px;
    position: relative;
    margin: 0 auto;
    padding: 70px 20px 40px 20px;
}

.b-footer-menu {
    margin-bottom: 30px;
}

.b-footer-menu li {
    font-size: 1.6em;
    color: #ffffff;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.3em;
    margin-right: 15px;
    display: inline-block;
    padding-right: 18px;
    background: url(/images/menu_sep2.png) no-repeat 100% 8px;
    margin-bottom: 10px;
}

.b-footer-menu ul ul {
    display: none;
}

.b-footer-menu li a {
    color: #ffffff;
    text-decoration: none;  
    border-bottom: 2px solid transparent;
    padding-bottom: 5px; 
}

.b-footer-menu li.last {
    padding-right: 0;
    background: none;
}

.b-footer-menu li a:hover {
    color: #ffffff;
    text-decoration: none;
    border-bottom: 2px solid #fff;
}

.b-copyright {
    padding: 110px 0 0 0;
    margin-bottom: 30px;
    font-size: 1.4em;
    line-height: 1.3em;
    color: #ffffff;
    font-weight: 400;
    background: url(/images/logo_small.png) no-repeat center top;
}

.b-webdev {
    text-align: center;
    font-size: 1.4em;
    line-height: 1.3em;
    color: #ffffff;
    background: #3cad6b;
    padding: 15px;
}

.b-webdev a {
    display: inline-block;
    color: #ffffff;
    padding: 3px 0 3px 36px;
    background: url(/images/ag.png) no-repeat center left;
}

@media only screen and (max-width : 1024px) {

}

@media only screen and (max-width : 920px) {
    .b-content.sidebar {
        float: none;
        margin-right: 0;
    }

    .b-content.sidebar .b-content-block {
        margin-right: 0;
    }

    .b-sidebar-block {
        float: none;
        width: 100%;
    }

    .b-media-gallery-shop, .b-item-image {
        width: 100%;
        float: none;
        max-width: 100%;
        margin: 0 0 70px 0;
    }
}

@media only screen and (max-width : 820px) {
    .b-block-about-text {
        padding: 20px 0;
        background: none;
    }  

    .b-block-name, h2.name {
        margin-left: 0;
    }

    .b-block-social {
  
    }

    .b-block-social-ico {
        padding-top: 20px;
    }

    .b-block-social-ico img {
        max-width: 180px;
    }

    .h-main-menu {
        padding: 30px 30px 15px 30px;
    }

    .h-content-holder {
        padding: 20px 30px;
    }

    .b-event-join, .b-merch-goto {
        top: 20px;
        right: 30px;
    }

    h1.title {
        font-size: 2.4em;
    }

    .b-static-text blockquote, .b-static-text blockquote p {
        font-size: 24px;
    }
}

@media only screen and (max-width : 680px) {
    .b-block-name, h2.name {
        font-size: 2.2em;
    }

    .b-content-top {
        padding: 60px 30px 35px 140px;
    }

    .b-tv-param {
        padding-bottom: 10px;
        background: none;
    }  

    .b-main-tvanons {
        margin-bottom: 20px;
    }

    .b-main-tvanons.andmore, .b-main-tvanons.yoga, .b-main-tvanons.zdorovo {
        padding-top: 0;
    }

    .b-event-show h1.title, .b-merch h1.title {
        padding-right: 0;
    }

    .b-event-join, .b-merch-goto {
        position: relative;
        top: auto;
        right: auto;
        margin-bottom: 20px;
    }
}